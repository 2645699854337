


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
.site-layout-background{
  text-align: end;
}
.site-layout-background a{
  color:#959595;
}




.logo {
  text-align: center;
  width: 120px;
  height: 60px;
  margin-left: 17%;
}
.logo img {
  width: 89%;
  height: 100%;
}

.layout-content {
  margin: 20px 16px;
  background: #fff;
  padding: 24px;
  min-height: 100%;
}



.footer a {
  color:#959595;
}

